import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';

import { Panzoom } from "@fancyapps/ui/dist/panzoom/panzoom.esm.js";
import { nl } from './nl.js';
import { Toolbar } from "@fancyapps/ui/dist/panzoom/panzoom.toolbar.esm.js";
import { Pins } from "@fancyapps/ui/dist/panzoom/panzoom.pins.esm.js";
// import { forEach } from 'core-js/core/array';

// DOM utility functions:
const el = (sel, parent) => (parent || document).querySelector(sel);
const els = (sel, parent) => (parent || document).querySelectorAll(sel);

// Utility functions:
const isStr = (str) => typeof str === 'string' || str instanceof String;

// On handler:
const on = (evtName, delegator, ...a) => 
  (isStr(delegator) ? el(delegator) : delegator)
    .addEventListener(evtName, ...isStr(a[0]) ? [e => e.target.closest(a[0]) && a[1](e), a[2]] : a);


var preventHistoryPush = false;
window.state = {}
const risicoprofielElem = document.getElementById("risicoprofiel");

const fancyboxOpts = {
  on: {
    loading: (fancybox) => {
    //   // console.log('loading, isOpeningSlide: ', fancybox.isOpeningSlide());
      if (fancybox.options.triggerEl.dataset.slug.split('-')[0] == 'aanjager') {
        // console.log(fancybox.options.trigger.dataset.slug.split('-')[0]);
        setMap('kaart-aanjagers', true)
      } else if (fancybox.options.triggerEl.dataset.slug.split('-')[0] == 'conclusies') {
        // console.log(fancybox.options.trigger.dataset.slug.split('-')[0]);
        setMap('kaart-conclusies', true)
      }
    //   // if (fancybox.isTopmost()) {
    //   // }
    },
    loaded: () => {
      let buttons = document.querySelectorAll('.popup .illustration .buttons a');
      buttons.forEach((button) => {
        button.setAttribute('href', '#'+button.dataset.slug)
      })
    }
  },
  // Hash: true,
  // afterShow: function(instance, current) {
  //   anotherpopup = false;
  // },
  // afterClose: function(instance, current) {
  //   if (anotherpopup) {
  //   } else {
  //     state.popup = "";
  //     state.popupSrc = "";
  //     setUrl(preventHistoryPush);
  //   }
  // },
  // closeExisting: true,
  tpl: {
    closeButton:
      '<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}"><?xml version="1.0" encoding="UTF-8"?><?xml version="1.0" encoding="UTF-8"?><svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345.12 345.12"><defs><style>.cls-1{fill:#003571;}.cls-2{fill:#008458;}.cls-3{fill:#fff;}</style></defs><circle class="cls-3" cx="172.56" cy="172.56" r="162.64"/><path class="cls-2" d="m172.56,345.12C77.41,345.12,0,267.71,0,172.56S77.41,0,172.56,0s172.56,77.41,172.56,172.56-77.41,172.56-172.56,172.56Zm0-325.28C88.35,19.84,19.84,88.35,19.84,172.56s68.51,152.72,152.72,152.72,152.72-68.51,152.72-152.72S256.77,19.84,172.56,19.84Z"/><path class="cls-1" d="m203.2,172.56l37.07-37.07c8.46-8.46,8.46-22.18,0-30.64s-22.18-8.46-30.64,0l-37.07,37.07-37.07-37.07c-8.46-8.46-22.18-8.46-30.64,0h0c-8.46,8.46-8.46,22.18,0,30.64l37.07,37.07-37.07,37.07c-8.46,8.46-8.46,22.18,0,30.64h0c8.46,8.46,22.18,8.46,30.64,0l37.07-37.07,37.07,37.07c8.46,8.46,22.18,8.46,30.64,0h0c8.46-8.46,8.46-22.18,0-30.64l-37.07-37.07h0Z"/></svg></button>',
    main: `<div class="fancybox__container" role="dialog" aria-modal="true" aria-label="{{MODAL}}" tabindex="-1">
      <div class="fancybox__backdrop"></div>
      <div class="fancybox__carousel"></div>
      <div class="fancybox__footer"></div>
      </div>`,
  },
  // toolbar: false,
  // baseTpl:
  //   '<div class="fancybox-container" role="dialog" tabindex="-1">' +
  //   '<div class="fancybox-bg"></div>' +
  //   '<div class="fancybox-inner">' +
  //   '<div class="fancybox-stage"></div>' +
  //   '<div class="fancybox-caption"></div>' +
  //   "</div>" +
  //   "</div>",
  wheel: false,
  // lang: "nl",
  l10n: {
      CLOSE: "Sluiten",
      NEXT: "Volgende",
      PREV: "Vorige",
      ERROR: "De gevraagde inhoud kan niet worden geladen. <br/> Probeert u het alstublieft later nog eens.",
      PLAY_START: "Start slideshow",
      PLAY_STOP: "Pause slideshow",
      FULL_SCREEN: "Full screen",
      THUMBS: "Thumbnails",
      DOWNLOAD: "Download",
      SHARE: "Delen",
      ZOOM: "Zoom"
  }
  // }
};

const panzoomOpts = {
  // bounds: {
  //   x: {
  //     min: 0,
  //     max: 6000,
  //   },
  //   y: {
  //     min: 0,
  //     max: 8000,
  //   },
  // },
  minScale: (panzoom) => {
    if (risicoprofielElem.dataset.map == 'kaart-crisistypen') {
      return 1.3 * panzoom.coverScale
    } else {
      return 1.05 * panzoom.coverScale
    }
    // return panzoom.coverScale
  },
  // maxScale: 'full',
  // minScale: 0.5,
  // minScale: 1,
  // friction: 0.5,
  // minScale: 2,
  // panOnlyZoomed: true,
  // bounds: {
  //   x: {
  //     min: 1850,
  //     max: 5950,
  //   },
  //   y: {
  //     min: 1350,
  //     max: 4350,
  //   },
  // },
  on: {
    beforeTransform: (event) => {

      let imgScale = document.getElementById('mapimage').offsetHeight / 6000;
      let transformScale = event.target.a;
      // console.log(imgScale, transformScale);
      // if (scale < 3) {
      //   scale = 1.5;
      // }
      // document.documentElement.style.setProperty('--panzoom-img-scale', imgScale);
      document.documentElement.style.setProperty('--panzoom-zoom-factor', imgScale*transformScale);
    },
    // afterLoad: (instance) => {
    //   console.log(instance);
    //   instance.zoomToFit();
    // },
    // afterTransform: (event) => {
    //   // console.log(event);
    //   let imgScale = document.getElementById('mapimage').offsetWidth / 8000;
    //   let transformScale = event.target.a;
    //   console.log(imgScale, transformScale);
    //   // if (scale < 3) {
    //   //   scale = 1.5;
    //   // }
    //   // document.documentElement.style.setProperty('--panzoom-img-scale', imgScale);
    //   document.documentElement.style.setProperty('--panzoom-zoom-factor', imgScale*transformScale);
    // },
    // init: (panzoom) => {
    //   // console.log('adding absolute');
    //   // document.getElementById('risicoprofiel-wrapper').classList.add('absolute')
    // },
    ready: (panzoom) => {
      // console.log('removing absolute');
      // document.getElementById('risicoprofiel-wrapper').classList.remove('absolute')
      
      // panzoom.zoomTo(1.3 * panzoom.coverScale, {friction: 0, x: 0, y: 0, originX: 0, originY: 0});
      // panzoom.reset();
      // panzoom.panTo({scale: 1.3 * panzoom.coverScale, x: +risicoprofielElem.offsetWidth/2, y: +risicoprofielElem.offsetHeight/2, originX: 0, originY: 0})
      // document.getElementById('risicoprofiel-wrapper').classList.add('absolute')
      // panzoom.reset();
      panzoom.zoomToCover()
      // panzoom.applyChange({panX: 4000, panY: 3000, originX: 0, originY: 0, scale: 1.3*panzoom.coverScale})
      // panzoom.zoomToFit();
      // panzoom.zoomToCover()
    },
    // beforeLoad: (panzoom) => {
    // //   // panzoom.reset();
    // },
  },
  dblClick: (panzoom, event) => {
    // console.log(panzoom, event)
    if (event && !(event.target.id == 'kaart-crisistypen') && !(event.target.id == 'kaart-aanjagers') && !(event.target.id == 'kaart-conclusies')) {
      if (event.target.dataset.type) {
        event.preventDefault;
        event.target.click();
      } else {
        return 'toggleZoom'
      }
    } else {
      return 'toggleZoom'
    }
  },
  click: false,
  width: 8000,
  height: 6000,
  bounce: false,
  // wheel: 'zoom',
  // transformParent: true,
  rubberband: false,
  l10n: nl,
  Toolbar: {
    display: [
      "zoomIn",
      "zoomOut",
      // "reset",
      // "toggleFS",
    ],
  },
}

function getState() {
  // try {
    // if (URLSearchParams) {
    if (window.location.hash) {
      let hash = window.location.hash.replace('#', '');
      if (hash == ('kaart-aanjagers' || 'kaart-crisistypen' || 'kaart-conclusies')) {
        // console.log('getstate', hash);
        window.state.kaart = hash;
      } else {
        return
      } 
    } else {
      window.state.kaart = 'kaart-crisistypen'
    }
   
    
      // for (var index in state) {
      //   if (parameters.get(index)) {
      //     state[index] = parameters.get(index);
      //   }
      // };
    // }
  // } catch(e) {
      // console.log(e.message);
  // }
};
  
function setUrl(kaartState, preventHistoryPush) {
  // if (state.kaart || state.popup) {
    // var url = '?';
    // if (state.kaart) {
    //   url += "kaart=" + state['kaart'];
    // };
    // if (state.popup) {
    //   url += "&popup=" + state['popup'];
    // }
    // console.log('seturl', kaartState);
    if (preventHistoryPush) {
      window.history.replaceState(state, null, '#'+kaartState);
    } else {
      window.history.pushState(state, null, '#'+kaartState);
    }
  // } else {
  //   return
  // }
};

function setView() {
  // console.log('setting view', state);
  if (state.kaart || state.popup) {
    // if (!document.querySelector(".mapimage."+state.kaart).classList.contains("visible")) {
      setMap(state.kaart);
    // };
    // if (state.popup) {
    //   if (!state.popupSrc) {
    //     state.popupSrc = document.querySelector('div.'+state.popup+"[data-link]").dataset.link;
    //   }
    //   new Fancybox([{
    //     src: state.popupSrc,
    //     type : 'ajax',
    //     opts: fancyboxOpts,
    //     filter: '#page-content'
    //   }]);
    // } else {
    //   Fancybox.close();
    // }
  };
};

  
async function loadImage(imageUrl) {
  // console.log('loading image with ', imageUrl);
  let img;
  const imageLoadPromise = new Promise(resolve => {
      img = new Image();
      img.onload = resolve;
      img.src = imageUrl;
  });

  await imageLoadPromise;
  // console.log("image loaded");
  return img;
}

function setMap(mapClass, inBackground) {
  // console.log("setMap with", risicoprofiel, mapClass, $(".mapimage").data(mapClass+"-src"));
  // risicoprofiel.zoomTo(2, {originX: 0, originY: 0})
  // if (!inBackground) {
  //   window.location.hash = mapClass;
  // }
  if (risicoprofielElem.dataset.map != mapClass) {
    document.querySelector(".map-selector.active").classList.remove("active");
    document.querySelector(".map-selector."+mapClass).classList.add("active");
    // document.querySelectorAll(".mapimage."+mapClass).forEach((element) => {
    //   if (!element.getAttribute("xlink:href")) {
    //     document.query.setAttribute("xlink:href", document.querySelector('.mapselector.'+mapClass).dataset.imagesrc);
    //   } else {
    //     return false;
    //   }
    // });
    risicoprofielElem.dataset.map = mapClass
    if (mapClass == 'kaart-aanjagers') {
      risicoprofielElem.classList.remove('show-locaties')
    }
    //   // document.getElementById('kaart-aanjagers').classList.add('f-panzoom__content');
    //   // document.getElementById('kaart-crisistypen').classList.remove('f-panzoom__content');
    //   // fancyappsPanzoomOpts.minScale = (panzoom) => panzoom.coverScale;
    //   // window.risicoprofiel.updateMetrics();
    //   // window.risicoprofiel.zoomToCover({originX: 4000, originY: 3000});

    //   // window.risicoprofiel.zoomTo('cover')
    //   // window.risicoprofiel.panTo({x: 3000,y: 4000});
    //   // PinchZoomer.get("map").zoomToCenter(2,1,0.5)
    //   // PinchZoomer.get('map').resetElem(true);
    //   // risicoprofiel.zoomToFit();
    // } else {
    //   // document.getElementById('kaart-aanjagers').classList.remove('f-panzoom__content');
    //   // document.getElementById('kaart-crisistypen').classList.add('f-panzoom__content');
    //   // window.risicoprofiel.updateMetrics();
    //   // window.risicoprofiel.zoomToCover();
    //   // window.risicoprofiel.panTo({x: -4000,y: -3000});
    // }
    // window.risicoprofiel.destroy();
    // window.risicoprofiel = new Panzoom(risicoprofielElem, panzoomOpts, { Toolbar, Pins });
    // document.getElementById("mapimage").setAttribute("src", document.getElementById("mapimage").getAttribute('data-'+mapClass+"-src"));
    // You can use it like this (using promise chaining):
    risicoprofielElem.classList.add('is-ghor-loading');
    // console.log('loading image with mapClass', mapClass);
    // risicoprofielElem.classList.remove('is-loading');
    // Or inside an async function:

    // const images = await loadImage(myImages);


    // $(".mapimage").removeClass("visible").removeClass("f-panzoom__content");
    // $(".mapimage."+mapClass).addClass("visible").addClass("f-panzoom__content");
    document.querySelectorAll("#risicoprofiel .button").forEach((el) => {
      el.classList.remove("enabled");
    })
    document.querySelectorAll("#risicoprofiel .button." + mapClass).forEach((el) => {
      el.classList.add("enabled");
    })
    document.querySelectorAll("a.filter").forEach((el) => {
      if (!el.classList.contains("." + mapClass)) {
        el.classList.remove("visible")
      }
    })
    document.querySelectorAll('.label .legend').forEach((el) => {
      if (!el.classList.contains('.'+mapClass)) {
        el.classList.remove('visible')
      }
    })
    // $(".label .legend").not("." + mapClass).removeClass("visible");
    if (document.querySelector("a.filter."+mapClass)) {
      document.querySelectorAll("a.filter."+mapClass).forEach((el) => {
        el.classList.add("visible")
      })
    }
    if (document.querySelector(".label .legend."+mapClass)) {
      document.querySelector(".label .legend."+mapClass).classList.add("visible");
    }
    loadImage(document.getElementById('data-' + mapClass + '-src').getAttribute('src')).then(img => {
      document.getElementById("mapimage").setAttribute("src", img.src);
      // console.log('loaded');
      risicoprofielElem.classList.remove('is-ghor-loading');

      // window.risicoprofiel.requestTick();
    })
  }
  
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('mapsection').classList.contains('visible')) {
    document.getElementById('mapsection').classList.remove('visible')
  } else {
    document.getElementById('mapsection').classList.add('visible')
  }
  document.body.classList.add('js');
  // document.querySelector('.section.footer').hide();
  Fancybox.bind('[data-fancybox]', fancyboxOpts);
  window.risicoprofiel = new Panzoom(risicoprofielElem, panzoomOpts, { Toolbar, Pins });
  document.getElementById('tables').classList.add('hidden')
  // risicoprofiel.zoomTo(2, {originX: 0, originY: 0})
  // window.risicoprofiel = new Panzoom(risicoprofielElem, fancyappsPanzoomOpts);
  // risicoprofielElem.parentElement.addEventListener('wheel', risicoprofiel.zoomWithWheel)
  // risicoprofielElem.parentElement.addEventListener('wheel', function(event) {
  //   if (!event.altKey) return
  //   risicoprofiel.zoomWithWheel(event)
  // })
  // myPanzoom.zoomTo(3600/myPanzoom.$content.getBoundingClientRect().width)
  var anotherpopup = false;
  if (history.state) {
    window.state = history.state;
  } else {
    window.state = {
      kaart: "kaart-crisistypen",
      // tables: false
      // popup: "",
      // popupSrc: ""
    };
  };
  
  window.onpopstate = function(event) {
    // console.log('onpopstate', event);
    if (event.state) {
      window.state = event.state;
      // anotherpopup = true;
      if (window.state.kaart) {
        setView();
      }
      // if (window.state.tables && !document.body.classList.contains('show-tables')) {
      //   document.getElementById('toggle-tables').click();
      // } else {
      //   document.getElementById('toggle-tables').click();
      // }
    } else {
      window.state = {
        kaart: "kaart-crisistypen",
        // tables: false
        // popup: ""
      };
    }
    // anotherpopup = true;
    // setView();
  };
  
  // if (window.location.hash == 'kaart-crisistypen' || window.location.hash == 'kaart-aanjagers') {
  //   setMap(window.location.hash, true)
  // }

  getState();
  if (window.state.kaart) {
    // setUrl(window.state.kaart, false);
    setView();
  }
  
  document.querySelectorAll(".map-selector").forEach((el) => {
    el.addEventListener('click', function(event) {
      // console.log(this.dataset, event);
      event.preventDefault()
      if (this.dataset.map == 'kaart-crisistypen') {
        // console.log('kaart-crisistypen, scale: ', 1.3 * risicoprofiel.coverScale);
        risicoprofielElem.classList.add('show-crisistypen')
        risicoprofielElem.classList.add('show-locaties')
        window.risicoprofiel.panTo({scale: 1.4 * risicoprofiel.coverScale, x: 0, y: 0})
        console.log('1.4');
        // window.risicoprofiel.requestTick();
      } else {
        // console.log('kaart-aanjagers, scale: ', 1.05 * risicoprofiel.coverScale);
        risicoprofielElem.classList.remove('show-crisistypen')
        risicoprofielElem.classList.remove('show-locaties')
        window.risicoprofiel.panTo({scale: 1 * risicoprofiel.coverScale, x: 0, y: 0})
        console.log('1');
        // window.risicoprofiel.requestTick();
      }
      // window.risicoprofiel.zoomToCover();
      if (this.classList.contains("active")) {
        return false;
      } else {
        // console.log('mapselector state.kaart:' , this.dataset.map);
        window.state.kaart = this.dataset.map;
        setUrl(this.dataset.map, false);
        setMap(this.dataset.map, false);
      }
    })
  })
  
  // document.querySelectorAll("a.filter.risicos").forEach((el) => {
  //   el.addEventListener('click', function(event) {
  //     event.preventDefault()
  //     document.querySelectorAll("#risicoprofiel button.risico").forEach((el) => {
  //       if (el.classList.contains('visible')) {
  //         el.classList.remove('visible')
  //       } else {
  //         el.classList.add('visible')
  //       }
  //     })
  //     if (this.classList.contains('active')) {
  //       this.classList.remove('active')
  //     } else {
  //       this.classList.add('active')
  //     }
  //   });
  // })
  document.getElementById('crisistypen-filter').addEventListener('click', function(event) {
    event.preventDefault()
    if (this.classList.contains('active')) {
      this.classList.remove('active')
      risicoprofielElem.classList.remove('show-crisistypen')
    } else {
      this.classList.add('active')
      risicoprofielElem.classList.add('show-crisistypen')
    }
  })
  document.getElementById('locaties-filter').addEventListener('click', function(event) {
    event.preventDefault()
    if (this.classList.contains('active')) {
      this.classList.remove('active')
      risicoprofielElem.classList.remove('show-locaties')
    } else {
      this.classList.add('active')
      risicoprofielElem.classList.add('show-locaties')
    }
  })
  // document.querySelectorAll("a.filter.gemeenten").forEach((el) => {
  //   el.addEventListener('click', function(event) {
  //     event.preventDefault()
  //     document.querySelectorAll("#risicoprofiel button.gemeente").forEach((el) => {
  //       if (el.classList.contains('visible')) {
  //         el.classList.remove('visible')
  //       } else {
  //         el.classList.add('visible')
  //       }
  //     })
  //     if (this.classList.contains('active')) {
  //       this.classList.remove('active')
  //     } else {
  //       this.classList.add('active')
  //     }
  //   });
  // })
  // $("a.filter.gemeenten").click(function(event) {
  //   event.preventDefault()
  //   $("#risicoprofiel button.gemeente").toggleClass("visible");
  //   $(this).toggleClass("active");
  // });
  
  // for(var i = 0; i < PinchZoomer.objs.length; i++) {
  //   var pz = PinchZoomer.objs[i],
  //     elem = pz.elem(),
  //     linkElems = elem.find(".button");
  //   for(var j = 0; j < linkElems.length; j++) {
  //     var linkElem = linkElems.eq(j);
  //     linkElem.css("cursor", "pointer");
  //     linkElem.addClass("map-button");
  //     var tapHandler = new Hammer(linkElem.get(0));
  //     tapHandler.on('tap', onTap);
  //   }
  // }
  // function onTap(e) {
  //   e.preventDefault();
  //   if (!e.target.dataset.query) {
  //     state.popup = $(e.target).parents('svg').data('query');
  //     state.popupSrc = $(e.target).parents('svg').data('link');
  //   } else {
  //     state.popup = $(e.target).data('query');
  //     state.popupSrc = $(e.target).data('link');
  //   }
  //   setView();
  //   setUrl(false);
  // }
  
  // $(document).on('click', '[data-type]', function(event){
  //   if (this.classList.contains("map-button")) {
  //     return;
  //   } else {
  //     event.preventDefault();
  //     state.popup = this.dataset.query;
  //     state.popupSrc = this.dataset.link;
  //     if (this.classList.contains('illustration')) {
  //       anotherpopup = true;
  //     } 
  //     console.log('setting view from onclick data-type')
  //     setView();
  //     setUrl(false);
  //   }
  // });
  
  // function smoothScrollingTo(target){
  //   $('html,body').stop().animate({
  //       'scrollTop': $(target).offset().top
  //     },500, 'swing', function() {
  //       }
  //   );
  // };
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const toggleMapSelectors = async (show) => {
    if (show) {
      document.body.classList.add('showing-tables')
      await delay(300);
      document.body.classList.add('show-tables')
      document.body.classList.remove('showing-tables')
    } else {
      document.body.classList.remove('show-tables')
      document.body.classList.add('hiding-tables')
      await delay(300);
      document.body.classList.remove('hiding-tables')
    }
  };
  on("click", "body", ".toggle-tables-from-popup", (evt) => {
    Fancybox.close();
    // console.log(evt.target);        // The dynamic child
    // console.log(evt.currentTarget); // The static parent (delegator)
  // });
  // document.getElementById('toggle-tables').addEventListener('click', function(event) {
    // $(".section.footer").show();
    // $(".section.tables").show();
    if (!document.getElementById('toggle-tables').classList.contains('active')) {
      document.getElementById('tables').classList.remove('hidden')
      // document.getElementById('tables').classList.remove('visible')
      document.getElementById('toggle-tables').classList.add('active')
      // setUrl('show-tables', false)
      toggleMapSelectors(true);
    }
    evt.preventDefault();
    // smoothScrollingTo(this.hash);
  });
  document.getElementById('toggle-tables').addEventListener('click', function(event) {
    Fancybox.close();
    if (document.getElementById('toggle-tables').classList.contains('active')) {
      document.getElementById('tables').classList.add('hidden')
      // document.getElementById('tables').classList.add('visible')
      document.getElementById('toggle-tables').classList.remove('active')
      // setUrl('', false)
      toggleMapSelectors(false);
    } else {
      document.getElementById('tables').classList.remove('hidden')
      // document.getElementById('tables').classList.remove('visible')
      document.getElementById('toggle-tables').classList.add('active')
      // setUrl('show-tables', false)
      toggleMapSelectors(true);
    }
    event.preventDefault();
  });
  
  var ih = window.innerHeight;
  try {
    document.documentElement.style.setProperty('--ih', ih + 'px');
  } catch(e) {
    console.log(e.message);
  }
  
  window.addEventListener('resize', function() {
    var ih = window.innerHeight;
    try {
      document.documentElement.style.setProperty('--ih', ih + 'px');
    } catch(e) {
      console.log(e.message);
    }
    window.risicoprofiel.updateMetrics();
    window.risicoprofiel.requestTick();
    // console.log('requesting tick');
    // window.risicoprofiel.zoomToFit();
  });
  
  var sbw = 0;
  var scrollbox = document.createElement('div');
  scrollbox.style.overflow = 'scroll';
  document.body.appendChild(scrollbox);
  sbw = scrollbox.offsetWidth - scrollbox.clientWidth;
  if (sbw < 10) {
    sbw = 10;
  } else {
    sbw = sbw + 5
  }
  document.body.removeChild(scrollbox);
  document.documentElement.style.setProperty('--sbw', sbw + 'px');


  // window.risicoprofiel.zoomToFit();
})